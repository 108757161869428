<template>
  <div class="tabs-container">
    <el-menu
        :default-active="activeTabId"
        @select="onTabSelected"
        class="tabs"
        mode="horizontal"
    >
      <el-menu-item
          :index="tab.id.toString()"
          :key="tab.id"
          v-for="(tab) in tabs"
      >
        {{ $t(tab.title) }} {{ tab.id !== '0' && tab.id !== 'create' ? `#${tab.id}` : null }}
        <font-awesome-icon
            @click.stop="closeTab(tab.id)"
            class="close-icon"
            icon="times-circle"
            v-if="tab.id !== '0'"
        />
      </el-menu-item>
    </el-menu>
    <keep-alive :max="10">
      <!--      <LiveDetector-->
      <!--        :path="$route.fullPath"-->
      <!--        :key="$route.fullPath.split('/').join('')"-->
      <!--      >-->
      <router-view
          :key="$route.fullPath.split('/').join('')"
          :path="$route.fullPath"
      />
      <!--      </LiveDetector>-->
    </keep-alive>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
// import LiveDetector from "@/views/WorkOrders/LiveDetector";

export default {
  name: 'TabsContainer',
  // components: {LiveDetector},
  data: () => ({
    // cachedComponents: []
  }),
  computed: {
    ...mapState('workOrders', ['tabs', 'activeTabId']),
  },
  watch: {
    activeTabId(newId) {
      const candidate = this.tabs.find(item => item.id.toString() === newId.toString());
      if (candidate && this.$route.fullPath !== candidate.route.fullPath) {
        this.$router.push(candidate.route.fullPath);
      }
    },
    '$route': {
      immediate: true,
      handler(route) {
        if (!this.tabs.some(item => item.route.fullPath === route.fullPath)) {
          if (route.params.id) {
            this.addTab({
              tab: {title: 'system.job', id: route.params.id, route: this.$route},
            });
          } else if (route.name === 'WorkOrderCreate') {
            this.addTab({
              tab: {title: 'system.create_work_order', id: 'create', route: this.$route},
            });
          }
        } else {
          const id = route.name === 'WorkOrdersList' ?
                     '0' : route.name === 'WorkOrderCreate' ?
                           'create' : route.params.id;
          this.setActiveTab({id});
        }
      },
    },
  },
  methods: {
    ...mapActions('workOrders', ['addTab', 'removeTab', 'setActiveTab']),
    onTabSelected(id) {
      this.setActiveTab({id});
    },
    closeTab(tabId) {
      this.removeTab({id: tabId});
    },
  },
};
</script>

<style lang="scss">
.tabs-container {
  .el-menu {
    width: 100%;
    white-space: nowrap !important;
    overflow-y: auto !important;

    .el-menu-item {
      height: 40px;
      line-height: 40px;
      display: inline !important;
    }
  }
}
</style>
<style
    lang="scss"
    scoped
>
$grey: grey;
$transparent: white;
.close-icon {
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
  border-radius: 50%;
  color: $transparent;
  background: $grey;
  font-size: 1.15em;

  &:hover {
    color: $grey;
    background: $transparent;
  }
}
</style>
